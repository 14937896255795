.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags .react-tags-list .react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 12px;
  border-radius: 20px;
  background: #F8310E;
  color: #fff;

  /* match the font styles */
  font-size: 14px;
  line-height: inherit;
}

/* .react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
} */

.react-tags .react-tags-list {
  margin: 0;
  padding: 0;
  display: inline;
}

.react-tags .react-tags-list .react-tags__selected-tag:hover,
.react-tags .react-tags-list .react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__selected-tag svg {
  font-size: 13px;
  margin-left: 5px;
  vertical-align: top;
  padding-top: 2px;
  cursor: pointer;
}

.react-tags .react-tags-input {
  position: relative;
  border-style: none;
  /* border: none; */
  display: inline-block;
  flex: 1 1 auto;
  margin: 5px;
  min-height: 100%;
  font-size: 12pt;
  outline: 0;
  width: auto;
  padding: 0;
  height: auto;
}
.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}
.react-tags-suggestions {
  position: relative;
}
.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 100%;
  }
}

.react-tags-suggestions .react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  overflow: overlay;
  max-height: 135px;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions ul li.react-tags__selected-tag {
  border-bottom: 1px solid #ddd;
  padding: 9px 8px;
  background: transparent;
  display: block;
  border-radius: 0px;
  color: #000;
  margin: 0px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions ul li.react-tags__selected-tag:hover {
  cursor: pointer;
  background: #F8310E;
  color: #fff;
  border-radius: 0px;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
